import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { courts } from "../../../Components/Data";
import { useDispatch, useSelector } from 'react-redux';
import { Addcases } from "../../../Actions/Actions";
import { TailSpin } from "react-loader-spinner";
import { fetchRoles } from "../../../Actions/Actions";


const Addcase = () => {
    const dispatch = useDispatch()
    const { isLoading } = useSelector((state) => ({

        isLoading: state.case.isLoading,


    }));

    const user = sessionStorage.getItem('user')
    const token = user ? JSON.parse(user).token : null


    const roles = useSelector((state) => state.case.roles);

    useEffect(() => {
        dispatch(fetchRoles()); // Dispatch the action to fetch roles
    }, [dispatch]);


    // Toggle usestates
    const [addcase, setAddcase] = useState(false);
    const [plaintiffs, setPlaintiffs] = useState(false)
    const [defendant, setDefendant] = useState(false)
    const [witness, setWitness] = useState(false)
    const [application, setApplication] = useState(false)
    const [dates, setDates] = useState(false)

    //  Input Fields Usestates
    const [suitno, setSuitno] = useState('');
    const [title, setTitle] = useState('');
    const [court, setCourt] = useState('');
    const [lawyer, setLawyer] = useState('');
    const [client, setClient] = useState('');
    const [valueofsuit, setValueofsuit] = useState("");
    const [natureOfSuit, setNatureOfSuit] = useState("");
    const [suitproperty, setSuitproperty] = useState("");
    const [plaintiffsClaim, setPlaintiffsClaim] = useState("");
    const [defendantsArgument, setDefendantsArgument] = useState("");
    const [currentStatus, setCurrentStatus] = useState("");
    const [plaintiffsRep, setPlaintiffsRep] = useState("");
    const [defendantRep, setDefendantRep] = useState("");
    const [restrainingOrder, setRestrainingOrder] = useState("");
    const [UnnecessaryAdjournments, setUnnecessaryadjournments] = useState('')
    const [other, setOther] = useState('')
    const [wordfile, setWordfile] = useState("");

    // Plaintiff's Submitted Documents
    const [plaintiffsSubmittedDocument, setPlaintiffsSubmittedDocument] = useState("");
    const [plaintiffsSubmittedDocuments, setPlaintiffsSubmittedDocuments] = useState([]);
    const [editIndex, setEditIndex] = useState(-1);
    const [editValue, setEditValue] = useState("");
    const handleAddPlaintiffsSubmittedDocument = () => {
        if (plaintiffsSubmittedDocument) {
            setPlaintiffsSubmittedDocuments((prevDocs) => [
                ...prevDocs,
                plaintiffsSubmittedDocument,
            ]);
            setPlaintiffsSubmittedDocument(""); // Reset the input field after adding
        }
    };
    const startEdit = (index, value) => {
        setEditIndex(index);
        setEditValue(value);
    };

    const saveEdit = (index) => {
        const updatedDocs = [...plaintiffsSubmittedDocuments];
        updatedDocs[index] = editValue;
        setPlaintiffsSubmittedDocuments(updatedDocs);
        setEditIndex(-1);
        setEditValue("");
    };


    // Additional Plaintiff's Documents
    const [additionalPlaintiffDocuments, setAdditionalPlaintiffDocuments] = useState([]);
    const [additionalPlaintiffDocument, setAdditionalPlaintiffDocument] = useState("");
    const [editIndexa, setEditIndexa] = useState(-1);
    const [editValuea, setEditValuea] = useState("");
    // working
    const handleAdditionalPlaintiffDocuments = () => {
        if (additionalPlaintiffDocument) {
            setAdditionalPlaintiffDocuments((prevDocs) => [
                ...prevDocs,
                additionalPlaintiffDocument,
            ]);
            setAdditionalPlaintiffDocument(""); // Reset the input field after adding
        }
    };
    // Additional plantiffs
    const startAEdit = (index, value) => {
        setEditIndexa(index);
        setEditValuea(value);
    };

    const saveAEdit = (index) => {
        const updatedDocs = [...additionalPlaintiffDocuments];
        updatedDocs[index] = editValuea;
        setAdditionalPlaintiffDocuments(updatedDocs);
        setEditIndexa(-1);
        setEditValuea("");
    };


    // Defendent's submitted documents
    const [defendantSubmittedDocumentmessage, setDefendantSubmittedDocumentmessage] = useState('');
    const [defendantsSubmittedDocuments, setDefendantsSubmittedDocuments] = useState([]);
    const [editIndex1, setEditIndex1] = useState(-1);
    const [editValue1, setEditValue1] = useState("");


    const handleAdddefendantsSubmittedDocuments = () => {
        if (defendantSubmittedDocumentmessage) {
            setDefendantsSubmittedDocuments((prevDocs) => [
                ...prevDocs,
                defendantSubmittedDocumentmessage,
            ]);
            setDefendantSubmittedDocumentmessage(""); // Reset the input field after adding
        }
    };
    // defendants
    const startdEdit = (index, value) => {
        setEditIndex1(index);
        setEditValue1(value);
    };
    const savedEdit = (index) => {
        const updatedDocs = [...defendantsSubmittedDocuments];
        updatedDocs[index] = editValue1;
        setDefendantsSubmittedDocuments(updatedDocs);
        setEditIndex1(-1);
        setEditValue1("");
    };


    // Additional Defendant's Documents
    const [additionalDefendantDocuments, setAdditionalDefendantDocuments] = useState([]);
    const [additionalDefendantDocument, setAdditionalDefendantDocument] = useState("");
    const [editIndexd, setEditIndexd] = useState(-1);
    const [editValued, setEditValued] = useState("");
    // defendants additional
    const handleAdditionalDefendantDocument = () => {
        if (additionalDefendantDocument) {
            setAdditionalDefendantDocuments((prevDocs) => [
                ...prevDocs,
                additionalDefendantDocument,
            ]);
            setAdditionalDefendantDocument(""); // Reset the input field after adding
        }
    };
    // defendants additional
    const startDEdit = (index, value) => {
        setEditIndexd(index);
        setEditValued(value);
    };
    const savedDEdit = (index) => {
        const updatedDocs = [...additionalDefendantDocuments];
        updatedDocs[index] = editValued;
        setAdditionalDefendantDocuments(updatedDocs);
        setEditIndexd(-1);
        setEditValued("");
    };


    const [formData, setFormData] = useState({
        srNo: "",
        application: "",
        applicationDate: "",
        reply: "",
        replyDate: "",
    });
    const [editIndexap, setEditIndexap] = useState(-1);
    const [entries, setEntries] = useState([]);


    // Application
    const handleAddClick = () => {
        if (editIndexap === -1) {
            // Create a new entry object from the formData
            const newEntry = {
                srNo: formData.srNo,
                application: formData.application,
                applicationDate: formData.applicationDate,
                reply: formData.reply,
                replyDate: formData.replyDate,
            };

            // Add the new entry to the entries array
            setEntries([...entries, newEntry]);

            // Clear the formData for the next entry
            setFormData({
                srNo: "",
                application: "",
                applicationDate: "",
                reply: "",
                replyDate: "",
            });
        } else {
            // Edit existing entry
            const updatedEntries = [...entries];
            updatedEntries[editIndexap] = {
                srNo: formData.srNo,
                application: formData.application,
                applicationDate: formData.applicationDate,
                reply: formData.reply,
                replyDate: formData.replyDate,
            };

            setEntries(updatedEntries);
            setEditIndexap(-1);

            // Clear the formData for the next entry
            setFormData({
                srNo: "",
                application: "",
                applicationDate: "",
                reply: "Not Available",
                replyDate: "",
            });
        }
    };

    const handleDeleteClick = (index) => {
        const updatedEntries = [...entries];
        updatedEntries.splice(index, 1);
        setEntries(updatedEntries);
    };

    const handleEditClick = (index) => {
        const entryToEdit = entries[index];
        setFormData(entryToEdit);
        setEditIndexap(index);
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const [noOfWitnessesOfPlaintiff, setNoOfWitnessesOfPlaintiff] = useState("");
    const [noOfWitnessesOfDefendant, setNoOfWitnessesOfDefendant] = useState("");
    const [WitnessesAvailablelist, setWitnessesAvailablelist] = useState('');
    const [DefendentAvailablelist, setDefendentAvailablelist] = useState('');


    const [filingOfSuit, setFilingOfSuit] = useState("");
    const [numberOfDefendants, setNumberOfDefendants] = useState("");
    const [poaFilingDatePlaintiff, setPoaFilingDatePlaintiff] = useState("");
    const [poaFilingDateDefendant, setPoaFilingDateDefendant] = useState("");
    const [defendantsWrittenStatementDate, setDefendantsWrittenStatementDate] = useState("");
    const [issuesFramedDate, setIssuesFramedDate] = useState("");
    const [restrainingOrderDate, setRestrainingOrderDate] = useState("");
    const [prevhearing, setPrevHearing] = useState("");
    const [nexthearing, setNextHearing] = useState("");





    const toggleaddcase = () => setAddcase((prev) => !prev);
    const toggleplaintiffs = () => setPlaintiffs((prev) => !prev);
    const toggleDefendant = () => setDefendant((prev) => !prev)
    const togglewitness = () => setWitness((prev) => !prev)
    const toggleapplication = () => setApplication((prev) => !prev)
    const toggledates = () => setDates((prev) => !prev)


    const AddCase = (e) => {
        e.preventDefault()
        let formData = new FormData();
        formData.append("Suitno", suitno);
        formData.append("title", title);
        formData.append("court", court);
        formData.append("lawyer", lawyer);
        formData.append("Clientname", client);
        formData.append("Valueofsuit", valueofsuit);
        formData.append("NatureofSuit", natureOfSuit);
        formData.append("Suiteproperty", suitproperty);
        formData.append("PlaintiffsClaim", plaintiffsClaim);
        formData.append("DefendantsArgument", defendantsArgument);
        formData.append("CurrentStatus", currentStatus);
        formData.append("PlaintiffsRepresentation", plaintiffsRep);
        formData.append("Defendantrepresentative", defendantRep);
        formData.append("RestrainingOrder", restrainingOrder);
        formData.append("UnnecessaryAdjournments", UnnecessaryAdjournments);
        formData.append("others", other);
        if (wordfile) {
            formData.append("wordFile", wordfile);
        }


        // 
        plaintiffsSubmittedDocuments.forEach((doc, index) => {
            formData.append(`PlaintiffsSubmittedDocuments[${index}]`, doc);
        });
        additionalPlaintiffDocuments.forEach((doc, index) => {
            formData.append(`AdditionalPlaintiffDocuments[${index}]`, doc);
        });
        defendantsSubmittedDocuments.forEach((doc, index) => {
            formData.append(`DefendantsSubmittedDocuments[${index}]`, doc);
        });

        additionalDefendantDocuments.forEach((doc, index) => {
            formData.append(`AdditionalDefendantDocuments[${index}]`, doc);
        });

        formData.append("NoofWitnessesofPlaintiff", noOfWitnessesOfPlaintiff);
        formData.append("WitnessesAvailablelist", WitnessesAvailablelist);
        formData.append("DefendentAvailablelist", DefendentAvailablelist);
        formData.append("NoofWitnessesofDefendant", noOfWitnessesOfDefendant);

        entries.forEach((entry, index) => {
            Object.keys(entry).forEach((key) => {
                formData.append(`application[${index}][${key}]`, entry[key]);
            });
        });


        formData.append("filingOfSuit", filingOfSuit);
        formData.append("numberOfDefendants", numberOfDefendants);
        formData.append("poaFilingDatePlaintiff", poaFilingDatePlaintiff);
        formData.append("poaFilingDateDefendant", poaFilingDateDefendant);
        formData.append("defendantsWrittenStatementDate", defendantsWrittenStatementDate);
        formData.append("issuesFramedDate", issuesFramedDate);
        formData.append("restrainingOrderDate", restrainingOrderDate);
        formData.append("prevhearing", prevhearing);
        formData.append("nexthearing", nexthearing);


        dispatch(Addcases(formData, token));
        setSuitno('')
        setNatureOfSuit("");
        setSuitproperty("");
        setPlaintiffsClaim("");
        setDefendantsArgument("");
        setCurrentStatus("");
        setPlaintiffsRep("");
        setDefendantRep("");
        setRestrainingOrder("");
        setPlaintiffsSubmittedDocuments([]);
        setPlaintiffsSubmittedDocument("");
        setAdditionalPlaintiffDocuments([]);
        setAdditionalPlaintiffDocument("");
        setDefendantsSubmittedDocuments([]);
        setAdditionalDefendantDocuments([]);
        setAdditionalDefendantDocument("");
        setDefendantSubmittedDocumentmessage("");
        setNoOfWitnessesOfPlaintiff("");
        setNoOfWitnessesOfDefendant("");
        setFilingOfSuit("");
        setNumberOfDefendants("");
        setPoaFilingDatePlaintiff("");
        setPoaFilingDateDefendant("");
        setDefendantsWrittenStatementDate("");
        setIssuesFramedDate("");
        setRestrainingOrderDate("");
        setPrevHearing("");
        setNextHearing("");
        setLawyer("");
        setCourt("");
        setTitle("");
        setClient("");
        setValueofsuit('')
        setUnnecessaryadjournments('')
    }




    return (
        <>
            <div className="bg-gray-100 h-auto p-5  w-full flex items-center justify-center">
                <div className="w-full mx-auto bg-white p-4 shadow-lg">
                    <h2 className="text-lg font-semibold text-center">Add Case</h2>

                    <form onSubmit={AddCase}>
                        <div
                            className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer  shadow-lg  flex justify-center p-4 mt-4"
                            onClick={toggleaddcase}
                        >
                            Add Case
                            {/* arrow icon */}
                        </div>
                        {addcase && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 1 */}
                                    <div>
                                        <label htmlFor="suitno-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Suit Number</label>
                                        <input
                                            type="text"
                                            id="suitno-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Suitno"
                                            onChange={(e) => setSuitno(e.target.value)}
                                            value={suitno}
                                        />
                                    </div>

                                    {/* 2 */}
                                    <div>
                                        <label htmlFor="title-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Title</label>
                                        <input
                                            type="text"
                                            id="title-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Title"
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                    </div>

                                    {/* 3 */}
                                    <div>
                                        <label htmlFor="countries-select" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Court</label>
                                        <select
                                            id="countries-select"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={court}
                                            onChange={(e) => setCourt(e.target.value)}
                                        >
                                            <option selected>Choose a Court</option>
                                            {courts.map((court) => (
                                                <option key={court.id} value={court.id}>
                                                    {court.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* 4 */}
                                    <div>
                                        <label htmlFor="lawyer-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Lawyer Name</label>
                                        <input
                                            type="text"
                                            id="lawyer-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Lawyer Name"
                                            onChange={(e) => setLawyer(e.target.value)}
                                            value={lawyer}
                                        />
                                    </div>
                                </div>


                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 5 */}
                                    <div>
                                        <label htmlFor="client-select" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Choose Client</label>
                                        <select
                                            id="client-select"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            value={client}
                                            onChange={(e) => setClient(e.target.value)}
                                        >
                                            <option selected>Choose Client</option>
                                            {roles.map((role, index) => (
                                                <option key={index} value={role}>
                                                    {role}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* 6 */}
                                    <div>
                                        <label htmlFor="valueofsuit-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Value of Suit</label>
                                        <input
                                            type="text"
                                            id="valueofsuit-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Value of Suit"
                                            onChange={(e) => setValueofsuit(e.target.value)}
                                            value={valueofsuit}
                                        />
                                    </div>

                                    {/* 7 */}
                                    <div>
                                        <label htmlFor="natureofsuit-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nature of Suit</label>
                                        <input
                                            type="text"
                                            id="natureofsuit-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Nature of Suit"
                                            onChange={(e) => setNatureOfSuit(e.target.value)}
                                            value={natureOfSuit}
                                        />
                                    </div>

                                    {/* 8 */}
                                    <div>
                                        <label htmlFor="suitproperty-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Suit Property</label>
                                        <input
                                            type="text"
                                            id="suitproperty-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Suit Property"
                                            onChange={(e) => setSuitproperty(e.target.value)}
                                            value={suitproperty}
                                        />
                                    </div>
                                </div>



                                {/* 3rd Row */}
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 9 */}
                                    <div>
                                        <label htmlFor="plaintiffs-claim-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Plaintiff's Claim</label>
                                        <input
                                            type="text"
                                            id="plaintiffs-claim-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Plaintiff's Claim"
                                            onChange={(e) => setPlaintiffsClaim(e.target.value)}
                                            value={plaintiffsClaim}
                                        />
                                    </div>

                                    {/* 10 */}
                                    <div>
                                        <label htmlFor="defendants-argument-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Defendant's Argument</label>
                                        <input
                                            type="text"
                                            id="defendants-argument-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Defendant's Argument"
                                            onChange={(e) => setDefendantsArgument(e.target.value)}
                                            value={defendantsArgument}
                                        />
                                    </div>

                                    {/* 11 */}
                                    <div>
                                        <label htmlFor="current-status-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Current Status</label>
                                        <input
                                            type="text"
                                            id="current-status-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Current Status"
                                            onChange={(e) => setCurrentStatus(e.target.value)}
                                            value={currentStatus}
                                        />
                                    </div>

                                    {/* 12 */}
                                    <div>
                                        <label htmlFor="plaintiffs-rep-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Plaintiff's Representation</label>
                                        <input
                                            type="text"
                                            id="plaintiffs-rep-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Plaintiff's Representation"
                                            onChange={(e) => setPlaintiffsRep(e.target.value)}
                                            value={plaintiffsRep}
                                        />
                                    </div>
                                </div>

                                {/* 4th Row */}
                                <div className="grid grid-cols-1 md:grid-cols-4 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 10 */}
                                    <div>
                                        <label htmlFor="defendant-rep-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Defendant Representative</label>
                                        <input
                                            type="text"
                                            id="defendant-rep-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Defendant representative"
                                            onChange={(e) => setDefendantRep(e.target.value)}
                                            value={defendantRep}
                                        />
                                    </div>

                                    {/* 11 */}
                                    <div>
                                        <label htmlFor="restraining-order-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Restraining Order</label>
                                        <input
                                            type="text"
                                            id="restraining-order-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Restraining Order"
                                            onChange={(e) => setRestrainingOrder(e.target.value)}
                                            value={restrainingOrder}
                                        />
                                    </div>

                                    {/* 12 */}
                                    <div>
                                        <label htmlFor="unnecessary-adjournments-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Unnecessary Adjournments</label>
                                        <input
                                            type="text"
                                            id="unnecessary-adjournments-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Unnecessary Adjournments"
                                            onChange={(e) => setUnnecessaryadjournments(e.target.value)}
                                            value={UnnecessaryAdjournments}
                                        />
                                    </div>

                                    {/* 13 */}
                                    <div>
                                        <label htmlFor="other-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Other</label>
                                        <input
                                            type="text"
                                            id="other-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Other"
                                            onChange={(e) => setOther(e.target.value)}
                                            value={other}
                                        />
                                    </div>
                                </div>

                                {/* 4th Row */}
                                <div className="grid grid-cols-1 md:grid-cols-1 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 14 */}
                                    <div>
                                        <label htmlFor="file_input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Upload File</label>
                                        <input
                                            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                            id="file_input"
                                            type="file"
                                            onChange={(e) => setWordfile(e.target.files[0])}
                                        />
                                    </div>
                                </div>

                            </>
                        )}

                        {/* Plaintiffs Submitted Documents Section-C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={toggleplaintiffs}>
                            Plaintiffs Submitted Documents
                        </div>

                        {plaintiffs && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 15*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Plaintiffs Submitted Document"
                                            onChange={(e) =>
                                                setPlaintiffsSubmittedDocument(e.target.value)
                                            } // Corrected this line
                                            value={plaintiffsSubmittedDocument}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button type="button" onClick={handleAddPlaintiffsSubmittedDocument}>Add</Button>
                                    </div>
                                    {/* plaintiff's bullet points */}
                                    <ol className=" list-decimal px-5 ">
                                        {plaintiffsSubmittedDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndex === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValue}
                                                        onChange={(e) => setEditValue(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndex === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => saveEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 16*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Additional Plaintiff Documents"
                                            onChange={(e) =>
                                                setAdditionalPlaintiffDocument(e.target.value)
                                            }
                                            value={additionalPlaintiffDocument}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button onClick={handleAdditionalPlaintiffDocuments}>Add</Button>
                                    </div>

                                    {/* additional palntiffs bullet points */}
                                    <ol className=" list-decimal px-5 ">
                                        {additionalPlaintiffDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndexa === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValuea}
                                                        onChange={(e) => setEditValuea(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndexa === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => saveAEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startAEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </div>



                            </>
                        )}


                        {/* Defendant's Submitted Documents -C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={toggleDefendant}>
                            Defendant's Submitted Documents
                        </div>

                        {defendant && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 17*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Defendant's Submitted Documents"
                                            onChange={(e) =>
                                                setDefendantSubmittedDocumentmessage(e.target.value)}
                                            value={defendantSubmittedDocumentmessage}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button onClick={handleAdddefendantsSubmittedDocuments}>Add</Button>
                                    </div>
                                    {/* Show Bullet Points */}
                                    <ol className=" list-decimal px-5 ">
                                        {defendantsSubmittedDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndex1 === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValue1}
                                                        onChange={(e) => setEditValue1(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndex1 === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => savedEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startdEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>



                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-12 gap-6 max-sm:gap-0 md:mt-5 md:px-5">
                                    {/* 18*/}
                                    <div className="col-span-11">
                                        <input
                                            type="text"
                                            id="default-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Additional Defendant Documents"
                                            onChange={(e) =>
                                                setAdditionalDefendantDocument(e.target.value)}
                                            value={additionalDefendantDocument}
                                        />
                                    </div>
                                    <div className="col-span-1 flex max-sm:justify-center">
                                        <Button onClick={handleAdditionalDefendantDocument}>Add</Button>
                                    </div>
                                    {/* Show Bullet Points */}
                                    <ol className=" list-decimal px-5 ">
                                        {additionalDefendantDocuments.map((doc, index) => (
                                            <li key={index} className="mt-5">
                                                {editIndexd === index ? (
                                                    <input
                                                        type="text"
                                                        value={editValued}
                                                        onChange={(e) => setEditValued(e.target.value)}
                                                        className=" border border-black  ring-1 ring-blue-500"
                                                    />
                                                ) : (
                                                    <span>{doc}</span>
                                                )}
                                                {editIndexd === index ? (
                                                    <button
                                                        type="button"
                                                        className=" bg-green-500 px-5 rounded-md py-1 ml-2"
                                                        onClick={() => savedDEdit(index)}
                                                    >
                                                        Save
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        className="bg-red-200 px-5 rounded-md ml-10 py-1 "
                                                        onClick={() => startDEdit(index, doc)}
                                                    >
                                                        Edit
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                    </ol>


                                </div>



                            </>
                        )}

                        {/* Witnesses-C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={togglewitness}>
                            Witnesses
                        </div>

                        {witness && (
                            <>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 19 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="List of Witnesses of Plaintiff"
                                        onChange={(e) => setNoOfWitnessesOfPlaintiff(e.target.value)} // Corrected this line
                                        value={noOfWitnessesOfPlaintiff}
                                    />



                                    {/* 19-A */}
                                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={WitnessesAvailablelist}
                                        onChange={(e) => setWitnessesAvailablelist(e.target.value)}
                                    >
                                        <option selected>Choose a option</option>
                                        <option value="Available">Available</option>
                                        <option value="Not Available">Not Available</option>
                                    </select>



                                    {/* 20 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="List of Witnesses of Defendant"
                                        onChange={(e) => setNoOfWitnessesOfDefendant(e.target.value)} // Corrected this line
                                        value={noOfWitnessesOfDefendant}
                                    />



                                    {/* 20-A */}
                                    <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        value={DefendentAvailablelist}
                                        onChange={(e) => setDefendentAvailablelist(e.target.value)}>
                                        <option selected>Choose a option</option>
                                        <option value="Available">Available</option>
                                        <option value="Not Available">Not Available</option>

                                    </select>


                                </div>

                                {/*  */}




                            </>
                        )}


                        {/* Application-C */}
                        <div className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer shadow-lg flex justify-center p-4 mt-4"
                            onClick={toggleapplication}>
                            Application
                        </div>

                        {application && (
                            <>

                                <div className="grid grid-cols-1 md:grid-cols-6 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 21 */}

                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Sr no"
                                        name="srNo"
                                        value={formData.srNo}
                                        onChange={handleInputChange}
                                    />

                                    {/* 22 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Application"
                                        name="application"
                                        value={formData.application}
                                        onChange={handleInputChange}
                                    />

                                    {/* 23 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Application date"
                                        name="applicationDate"
                                        value={formData.applicationDate}
                                        onChange={handleInputChange}
                                    />
                                    {/* 24 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Reply"
                                        name="reply"
                                        value={formData.reply}
                                        onChange={handleInputChange}
                                    />
                                    {/* 25 */}
                                    <input
                                        type="text"
                                        id="default-input"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Reply date"
                                        name="replyDate"
                                        value={formData.replyDate}
                                        onChange={handleInputChange}
                                    />


                                    <Button onClick={handleAddClick}> {editIndexap === -1 ? "Add" : "Update"}</Button>



                                </div>

                                {/* Table */}

                                <table className="border border-collapse border-black w-full mt-5 text-center">
                                    <thead>
                                        <tr>
                                            <th className="border border-black">srNo</th>
                                            <th className="border border-black">Application</th>
                                            <th className="border border-black">Application Date</th>
                                            <th className="border border-black">Reply</th>
                                            <th className="border border-black">Reply Date</th>
                                            <th className="border border-black">Edit</th>
                                            <th className="border border-black">Delete</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entries.map((entry, index) => (
                                            <tr key={index}>
                                                <td className="border border-black">{entry.srNo}</td>
                                                <td className="border border-black">
                                                    {entry.application}
                                                </td>
                                                <td className="border border-black">
                                                    {entry.applicationDate}
                                                </td>
                                                <td className="border border-black">{entry.reply}</td>
                                                <td className="border border-black">
                                                    {entry.replyDate}
                                                </td>
                                                {/* Display other fields here */}
                                                <td className="border border-black">
                                                    <button
                                                        className=" bg-red-500 px-3 py-1 rounded"
                                                        type="button"
                                                        onClick={() => handleEditClick(index)}
                                                    >
                                                        Edit
                                                    </button>
                                                </td>
                                                <td className="border border-black">
                                                    <button
                                                        className=" bg-red-500 px-3 py-1 rounded"
                                                        type="button"
                                                        onClick={() => handleDeleteClick(index)}
                                                    >
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>




                            </>
                        )}


                        {/* Dates */}
                        <div
                            className="section-toggle bg-gray-200 hover:bg-gray-300 cursor-pointer  shadow-lg  flex justify-center p-4 mt-4"
                            onClick={toggledates}
                        >
                            Dates
                            {/* arrow icon */}
                        </div>
                        {dates && (
                            <>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-sm:gap-0 md:mt-5 md:px-10">
                                    {/* 26 */}
                                    <div>
                                        <label htmlFor="filing-of-suit-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Filing of Suit</label>
                                        <input
                                            type="text"
                                            id="filing-of-suit-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Filing of Suit"
                                            onChange={(e) => setFilingOfSuit(e.target.value)}
                                            value={filingOfSuit}
                                        />
                                    </div>
                                    {/* 27 */}
                                    <div>
                                        <label htmlFor="number-of-defendants-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">No. of Defendant</label>
                                        <input
                                            type="text"
                                            id="number-of-defendants-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="No. of Defendant"
                                            onChange={(e) => setNumberOfDefendants(e.target.value)}
                                            value={numberOfDefendants}
                                        />
                                    </div>
                                    {/* 28 */}
                                    <div>
                                        <label htmlFor="poa-filing-date-plaintiff-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">POA Filling Date Plaintiff</label>
                                        <input
                                            type="text"
                                            id="poa-filing-date-plaintiff-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="POA Filling Date Plaintiff"
                                            onChange={(e) => setPoaFilingDatePlaintiff(e.target.value)}
                                            value={poaFilingDatePlaintiff}
                                        />
                                    </div>
                                    {/* 29 */}
                                    <div>
                                        <label htmlFor="poa-filing-date-defendant-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">POA Filing Date Defendant</label>
                                        <input
                                            type="text"
                                            id="poa-filing-date-defendant-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="POA Filing Date Defendant"
                                            onChange={(e) => setPoaFilingDateDefendant(e.target.value)}
                                            value={poaFilingDateDefendant}
                                        />
                                    </div>
                                    {/* 30 */}
                                    <div>
                                        <label htmlFor="defendants-written-statement-date-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Defendant's Written Statement</label>
                                        <input
                                            type="text"
                                            id="defendants-written-statement-date-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Defendant's Written Statement"
                                            onChange={(e) => setDefendantsWrittenStatementDate(e.target.value)}
                                            value={defendantsWrittenStatementDate}
                                        />
                                    </div>
                                    {/* 31 */}
                                    <div>
                                        <label htmlFor="issues-framed-date-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Issues Framed</label>
                                        <input
                                            type="text"
                                            id="issues-framed-date-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Issues Framed"
                                            onChange={(e) => setIssuesFramedDate(e.target.value)}
                                            value={issuesFramedDate}
                                        />
                                    </div>
                                    {/* 32 */}
                                    <div>
                                        <label htmlFor="restraining-order-date-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Restraining Order</label>
                                        <input
                                            type="text"
                                            id="restraining-order-date-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Restraining Order"
                                            onChange={(e) => setRestrainingOrderDate(e.target.value)}
                                            value={restrainingOrderDate}
                                        />
                                    </div>
                                    {/* 33 */}
                                    <div>
                                        <label htmlFor="last-date-of-hearing-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Date of Hearing</label>
                                        <input
                                            type="text"
                                            id="last-date-of-hearing-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Last Date of Hearing"
                                            onChange={(e) => setPrevHearing(e.target.value)}
                                            value={prevhearing}
                                        />
                                    </div>
                                    {/* 34 */}
                                    <div>
                                        <label htmlFor="next-date-of-hearing-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Next Date of Hearing</label>
                                        <input
                                            type="text"
                                            id="next-date-of-hearing-input"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Next date of Hearing"
                                            onChange={(e) => setNextHearing(e.target.value)}
                                            value={nexthearing}
                                        />
                                    </div>
                                </div>

                            </>
                        )}

                        <div className="flex justify-center mt-10">
                            <button
                                className="bg-gray-400 hover:bg-gray-500 text-white font-semibold px-5 py-2"
                                type="submit"
                                disabled={isLoading}
                            // Assuming this triggers the update
                            >
                                {isLoading ? (
                                    <TailSpin color="#FFFFFF" height={20} width={20} />
                                ) : (
                                    'ADD'
                                )}
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </>
    );
};

export default Addcase;
