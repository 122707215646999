import React, { useEffect, useState } from 'react'
import { URL_API } from '../../../Components/Data'

const Clientview = () => {
    const [casesrole, setCasesrole] = useState([])
    const user = sessionStorage.getItem('user')
    const token = user ? JSON.parse(user).token : null

    useEffect(() => {
        const fetchProtectedData = async () => {


            try {
                const response = await fetch(`${URL_API}/api/v1/users/getusersonrole`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`

                    }
                });

                if (!response.ok) {
                    throw new Error('Request failed');
                }

                const data = await response.json();
                setCasesrole(data)
            } catch (error) {
                console.error('Error fetching protected data:', error);
            }
        };
        fetchProtectedData()
    }, [casesrole, token])

    console.log(casesrole)
    return (
        <div class="container mx-auto my-8">
            <h1 class="text-2xl font-semibold text-center text-gray-800 mb-6">CASES</h1>
            <div class="overflow-x-auto">
                <table class="min-w-full leading-normal">
                    <tbody>
                        {
                            casesrole.map((item, index) => (
                                <tr key={index} class="hover:bg-gray-100">
                                    <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div class="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                    <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div class="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                    <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div class="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                    <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div class="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                    <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div class="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                    <td class="px-5 py-3 border-b border-gray-200 bg-white text-sm">
                                        <div class="text-gray-900 whitespace-no-wrap">{item.title}</div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>


    )
}

export default Clientview
